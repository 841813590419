import axios from "axios";
import React, { useState, useEffect } from 'react';
import { MDBCard, MDBDatepicker, MDBCardBody, MDBBtn, MDBRow, MDBCol, MDBAlert, MDBIcon, MDBCheckbox, MDBSpinner } from 'mdb-react-ui-kit';
import logo from "./../components/images/GymLogo.png";
import { getWaiverSettings, createWaiver, logEvent } from "../services/apiServices.js";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from 'react-ga';
import { useLocation } from 'react-router';
// import SignatureCanvas from 'react-signature-canvas'
import { isEmail, isEmpty } from '../validators/helper';
import { CountryDropdown } from "react-country-region-selector";
import SuccessScreen from "../components/sucessScreen.js";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { googleAnalytic } from "../components/googleAnalytics";
import { browserName, browserVersion } from "react-device-detect";

function DigitalWaiver() {
    function decryptId(encryptedId) {
        return atob(encryptedId);
    }
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const encryptedGymId = params.get('id') ? params.get('id') : "";
    const memberid = params.get('mId') ? params.get('mId') : "";
    const decryptedUID = decryptId(encryptedGymId);
    let uniqueID = decryptedUID;
    // const logourl = (JSON.parse(localStorage.getItem("loggedUserInfo"))).gymlogoUrl;
    const [data, setData] = useState(null);
    const [AdultName, setAdultName] = useState(false);
    const [Error2, setError2] = useState("")
    const [error, setError] = useState({});
    const [merror, setmerror] = useState([]);
    const [noError, setNoError] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        fname: "",
        lname: "",
        relation: "S/o",
        relationfirstname: "",
        relationlastname: "",
        dateofbirth: "01/01/2000",
        consultingadultfirstname: "",
        consultingadultlastname: "",
        consultingadultrelation: "",
        // consultingadultrelationfirstname: "",
        // consultingadultrelationlastname: "",
        consultingadultcontact: "",
        gender: "Male",
        phone: "",
        email: "",
        street: "",
        area: "",
        city: "",
        country: "India",
        emergencyfirstname: "",
        emergencylastname: "",
        emergencycontact: "",
        emergencyrelation: "",
        bloodgroup: "A+",
        mId: memberid,
        checkBox: false,

    });


    const [logourl, setLogourl] = useState(logo)
    const [imageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);
    useEffect(() => {

        const handleImageLoad = () => {
            setImageLoaded(true);
        };

        const handleImageError = () => {
            setImageLoaded(false);
        };
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = logourl;

        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [logourl]);
    async function fetchData() {
        const response = await getWaiverSettings(uniqueID);
        if (!response) {
            return;
        }
        if (response == 'false') {
            return;
        }
        const recinfo = await response.json();
        console.log(recinfo);
        const updatedInfo = { ...form };
        if (recinfo.gymid) {
            setData(recinfo);
            updatedInfo.gymid = recinfo.gymid;
            setLogourl(recinfo.gymInfo.logourl);
        } else {
            setError2("Link has been expired. Please contact GymAdmin");
        }
        setForm(updatedInfo);
    }
    useEffect(() => {
        if (!form.ipAddress && form.gymid) {
            setIpAddress();
        }
        if (!form.checkBox && form.gymid) {
            setSubmitDisable(true);
        } else {
            setSubmitDisable(false);
        }
    }, [form]);
    async function setIpAddress() {
        try {
            const url = process.env.REACT_APP_IP_ADDRESS_URL || 'https://api.ipify.org?format=json';
            const res = await axios.get(url);
            setForm({ ...form, ipAddress: res.data.ip });
        } catch (e) {
            console.log(e);
        }
        return;
    }


    const onSubmitForm = async (e) => {
        e.preventDefault();
        const updatedForm = {
            ...form,
            agreement: data.agreement,
            title: (data && data.title) ? data.title : "Digital waiver Form",
            checkboxcontent: data.checkboxcontent,
        };
        if (updatedForm.gymid === "" || !updatedForm.gymid) {
            setError2("Something went wrong. Please contact GymAdmin");
        } else {
            let rec = await createWaiver(updatedForm);
            if (!rec || rec == "false") {
                setError2("Something went wrong. Please try again.");
                setLoading(false);
                setSubmitDisable(false);
                return;
            }
            let responseObj = await rec.json();
            console.log(responseObj);
            if (responseObj.status) {
                setNoError(true);
                let ip = form.ipAddress;
                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                let flag = `Waiver Form Submitted Successfull (${form.fname} ${form.lname})`;
                let Eventtype = "Digital Waiver";
                let gymid = form.gymid;
                let username1 = "Digital Waiver Form";
                logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
                googleAnalytic("Digitalwaiver", 'DigitalWaiver', 'Submit Button', 'Member added from Digital Waiver Form');
                setError2('');
            } else {
                setError2("Something went wrong. Please try again.");
            }
            setLoading(false);
            setSubmitDisable(false);
        }
        return;
    }
    const submitHandler = (e) => {
        e.preventDefault();
        console.log(navigator);
        if (navigator.onLine) {
            console.log("online");
        } else {
            console.log("OFFline");
            setLoading(false);
            setSubmitDisable(false);
            setError2("No Internet Connection. Please check your internet connection and try again.");
            return;
        }
        let errors = validateForm(e);
        if (errors == true) {
            setLoading(true);
            setError({});
            setmerror([]);
            // setTimeout(() => {            
            onSubmitForm(e);
            // }, 10000);
            setTimeout(() => {
                setLoading(false);
                setSubmitDisable(false);
            }, 20000);
        } else {
            setError(errors);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    const isValidDate = (dateString) => {
        const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
        return datePattern.test(dateString);
    };
    function calculateAge(birthDate) {
        const today = new Date();
        const birthDateParts = birthDate.split("/");
        const birthYear = parseInt(birthDateParts[2]);
        const birthMonth = parseInt(birthDateParts[1]) - 1; // Month is zero-based
        const birthDay = parseInt(birthDateParts[0]);

        const age = today.getFullYear() - birthYear;
        const monthDiff = today.getMonth() - birthMonth;

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDay)) {
            return age - 1;
        }
        return age;
    }
    const currentDate = new Date();
    function updateDOB(date) {
        if (isValidDate(date)) {
            const age = calculateAge(date);
            updateForm({ dateofbirth: date, age: age });
            if (Number(age) < 18) {
                setAdultName(true);
            } else {
                setAdultName(false)
            }
        } else {
            delete form.age;
            updateForm({ dateofbirth: date });
        }
    }
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }
    const isAlphabetical = (str) => /^[a-zA-Z\s]+$/.test(str);
    let validateForm = (e) => {
        e.preventDefault();
        console.log("Validating form...");
        let errors = {};
        setError({})
        const error = []
        setmerror([]);

        if (isEmpty(form.fname)) {
            errors.fname = "Field can't be empty.";
        }
        else if (!isEmpty(form.fname) && !isAlphabetical(form.fname)) {
            errors.fname = "Name can be only alphabetic characters";
        }
        if (errors.fname) { error.push("First Name") }

        if (isEmpty(form.lname)) {
            errors.lname = "Field can't be empty.";
        } else if (!isEmpty(form.lname) && !isAlphabetical(form.lname)) {
            errors.lname = "Name can be only alphabetic characters";
        }
        if (errors.lname) { error.push("Last Name") }

        //// relationships validation
        if (isEmpty(form.relationfirstname)) {
            errors.relationfirstname = "Field can't be empty.";
        } else if (!isEmpty(form.relationfirstname) && !isAlphabetical(form.relationfirstname)) {
            errors.relationfirstname = "Name can be only alphabetic characters";
        }
        if (errors.relationfirstname) { error.push("Relation") }


        //// consult validations age less than 18
        if (AdultName) {
            if (isEmpty(form.consultingadultfirstname)) {
                errors.consultingadultfirstname = "Field can't be empty.";
            } else if (!isEmpty(form.consultingadultfirstname) && !isAlphabetical(form.consultingadultfirstname)) {
                errors.consultingadultfirstname = "Name can be only alphabetic characters";
            }

            if (isEmpty(form.consultingadultlastname)) {
                errors.consultingadultlastname = "Field can't be empty.";
            } else if (!isEmpty(form.consultingadultlastname) && !isAlphabetical(form.consultingadultlastname)) {
                errors.consultingadultlastname = "Name can be only alphabetic characters";
            }

            if (isEmpty(form.consultingadultcontact)) {
                errors.consultingadultcontact = "Field can't be empty.";
            } else if (!isEmpty(form.consultingadultcontact) && !isValidPhoneNumber(form.consultingadultcontact)) {
                errors.consultingadultcontact = "Please enter valid Phone Number";
            }
            if (isEmpty(form.consultingadultrelation)) {
                errors.consultingadultrelation = "Field can't be empty.";
            } else if (!isEmpty(form.consultingadultcontact) && !isAlphabetical(form.consultingadultrelation)) {
                errors.consultingadultrelation = "Relationship can be only alphabetic characters";
            }

            if (errors.consultingadultrelation || errors.consultingadultcontact || errors.consultingadultfirstname || errors.consultingadultlastname) {
                error.push("Consulting Adult")
            }
        }

        //// Address validations
        if (data && data.address === 1) {
            if (isEmpty(form.street)) {
                errors.street = "Field can't be empty.";
            }
            if (isEmpty(form.area)) {
                errors.area = "Field can't be empty.";
            }
            if (isEmpty(form.city)) {
                errors.city = "Field can't be empty.";
            }
            if (errors.street || errors.area || errors.city) { error.push("Address") }
        }

        //// Email validations
        if (data && data.email === 1) {
            if (isEmpty(form.email)) {
                errors.email = "Field can't be empty.";
            } else if (!isEmpty(form.email) && !isEmail(form.email)) {
                errors.email = "Please enter valid email address";
            }
        } else if (data && data.email === 2) {
            if (!isEmpty(form.email) && !isEmail(form.email)) {
                errors.email = "Please enter valid email address";
            }
        }
        if (errors.email) { error.push("Email") }

        //// number validations
        if (data && data.phone === 1) {
            if (isEmpty(form.phone)) {
                errors.phone = "Field can't be empty.";
            } else if (!isEmpty(form.phone) && !isValidPhoneNumber(form.phone)) {
                errors.phone = "Please enter valid Phone Number";
            }
        } else if (data && data.phone === 2) {
            if (!isEmpty(form.phone) && !form.phone && !isValidPhoneNumber(form.phone)) {
                errors.phone = "Please enter valid Phone Number";
            }
        }
        if (errors.phone) { error.push("Phone number") }

        //// emergency contact validations
        if (data && data.emergencycontact === 1) {
            if (isEmpty(form.emergencyfirstname)) {
                errors.emergencyfirstname = "Field can't be empty.";
            } else if (!isEmpty(form.emergencyfirstname) && !isAlphabetical(form.emergencyfirstname)) {
                errors.emergencyfirstname = "Name can be only alphabetic characters";
            }
            if (isEmpty(form.emergencylastname)) {
                errors.emergencylastname = "Field can't be empty.";
            } else if (!isEmpty(form.emergencylastname) && !isAlphabetical(form.emergencylastname)) {
                errors.emergencylastname = "Name can be only alphabetic characters";
            }
            if (isEmpty(form.emergencyrelation)) {
                errors.emergencyrelation = "Field can't be empty.";
            } else if (!isEmpty(form.emergencyrelation) && !isAlphabetical(form.emergencyrelation)) {
                errors.emergencyrelation = "Relationship can be only alphabetic characters";
            }
            if (isEmpty(form.emergencycontact)) {
                errors.emergencycontact = "Field can't be empty.";
            } else if (!isEmpty(form.emergencycontact) && !isValidPhoneNumber(form.emergencycontact)) {
                errors.emergencycontact = "Please enter valid Phone Number";
            }
        } else if (data && data.emergencycontact === 2) {
            if (!isEmpty(form.emergencyfirstname) && !isAlphabetical(form.emergencyfirstname)) {
                errors.emergencyfirstname = "Name can be only alphabetic characters";
            }
            if (!isEmpty(form.emergencylastname) && !isAlphabetical(form.emergencylastname)) {
                errors.emergencylastname = "Name can be only alphabetic characters";
            }
            if (!isEmpty(form.emergencyrelation) && !isAlphabetical(form.emergencyrelation)) {
                errors.emergencyrelation = "Relationship can be only alphabetic characters";
            }
            if (!isEmpty(form.emergencycontact) && !isValidPhoneNumber(form.emergencycontact)) {
                errors.emergencycontact = "Please enter valid Phone Number";
            }
        }
        if (errors.emergencycontact || errors.emergencyrelation || errors.emergencylastname || errors.emergencyfirstname) { error.push("Emergency Contact") }

        if (!form.checkBox) {
            errors.checkBox = "Please click on the checkBox";
        }

        if (!isValidDate(form.dateofbirth)) {
            errors.dateofbirth = "Please select a valid date";
        }
        // if (!signatureDone) {
        //     errors.signatureDone = "Please sign the form before submitting.";
        // }
        console.log(errors);
        setmerror(error)
        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };
    const allbloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
    return (
        <React.Fragment>
            <>
                <div className="h-100vhWave">
                    <MDBCard className='waveForm'>
                        <MDBCardBody className='py-3 position-relative'>
                            <div className="mobile-Head">

                                <div className='d-flex align-items-center Waiver-logo'>
                                    <div className='d-flex align-items-center '>
                                        <img src={imageLoaded ? logourl : logo} className="w-20 mob-w-40" />
                                        <div className='d-flex align-items-start flex-column mt-2 border-start ps-2 border-2'>
                                            <h5 className='logoColor'>{(data && data.gymInfo && data.gymInfo.businessName) ? data.gymInfo.businessName : "GYM Admin Online"}</h5>
                                            <div className='d-flex'>
                                                {/* <MDBIcon fas icon="map-marker-alt" className='mt-1' /> */}
                                                <p className='m-0'>
                                                    {(data && data.gymInfo) ? (
                                                        (data.gymInfo.address ? data.gymInfo.address : '') +
                                                        ((data.gymInfo.address && data.gymInfo.city) ? ', ' : '') +
                                                        (data.gymInfo.city ? (data.gymInfo.city) : '')
                                                    ) : "Suadaraiah Vignana Kendram, Gachibowli, Hyderabad"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="routing-border">

                                </div>
                            </div>
                            <div className='text-center mt-2 mb-2'>
                                <h5 className='text-dark m-0'>{(data && data.title) ? data.title : "Digital Waiver Form"}</h5>
                            </div>

                            {noError ?
                                <SuccessScreen />
                                : <>
                                    <MDBRow>
                                        <MDBCol sm={12} md={3}>
                                            <div className="tel-numbers w-100 mt-2">
                                                <strong>First Name<span className="text-danger">*</span></strong>
                                            </div>
                                            <div className="pt-1 w-100 ">
                                                <input placeholder='First Name' type="text" className="w-100 form-control"
                                                    onChange={(e) => updateForm({ fname: e.target.value })} value={form.fname} />
                                                {error.fname && (<span className="clr-red waiver-error-txt">{error.fname}</span>)}
                                            </div>
                                        </MDBCol>
                                        <MDBCol sm={12} md={3}>
                                            <div className="tel-numbers w-100 mt-2">
                                                <strong>Last Name<span className="text-danger">*</span></strong>
                                            </div>
                                            <div className="pt-1 w-100 ">
                                                <input placeholder='Last Name' type="text" className="w-100 form-control"
                                                    onChange={(e) => updateForm({ lname: e.target.value })} value={form.lname} />
                                                {error.lname && (<p className="clr-red waiver-error-txt">{error.lname}</p>)}
                                            </div>
                                        </MDBCol>
                                        <MDBCol sm={12} md={6}>

                                            <div className="tel-numbers mt-2">
                                                <strong>Relation<span className="text-danger">*</span></strong>
                                            </div>
                                            <div className="form pt-1 w-100">
                                                <div className="d-flex align-items-start">
                                                    <select className='form-select w-25 me-2' onChange={(e) => updateForm({ relation: e.target.value })} >
                                                        <option value={"S/o"}>S/o</option>
                                                        <option value={"D/o"}>D/o</option>
                                                        <option value={"W/o"}>W/o</option>
                                                        <option value={"H/o"}>H/o</option>
                                                        <option value={"Sibling"}>Sibling</option>
                                                        <option value={"Guardian"}>Guardian</option>
                                                    </select>
                                                    <div className="w-50 ">
                                                        <input placeholder='First Name' type="text" className="w-100 form-control"
                                                            onChange={(e) => updateForm({ relationfirstname: e.target.value })} value={form.relationfirstname} />
                                                        {error.relationfirstname && (<><p className="clr-red waiver-error-txt">{error.relationfirstname}</p></>)}
                                                    </div>
                                                    <div className="w-50 ps-2" >

                                                        <input placeholder='Last Name' type="text" className="w-100 form-control"
                                                            onChange={(e) => updateForm({ relationlastname: e.target.value })} value={form.relationlastname} />
                                                        {error.relationlastname && (<><p className="clr-red waiver-error-txt">{error.relationlastname}</p></>)}
                                                    </div>

                                                </div>
                                            </div>
                                        </MDBCol>


                                        <MDBCol sm={12} md={3}>
                                            <div className="tel-numbers  mt-2">
                                                <strong>DOB</strong>
                                            </div>
                                            <div className="form pt-1 w-100">

                                                <MDBDatepicker
                                                    value={form.dateofbirth}
                                                    onChange={(date) => updateDOB(date)}
                                                    disableFuture
                                                    inputToggle
                                                    selectOnClick
                                                />

                                            </div>
                                            {error.dateofbirth && (<><p className="clr-red waiver-error-txt">{error.dateofbirth}</p></>)}

                                        </MDBCol>

                                        {(data == null || data.bloodgroup != 3) &&
                                            <MDBCol sm={12} md={3}>
                                                <div className="tel-numbers  mt-2">
                                                    <strong>Blood Group</strong>
                                                </div>
                                                <select className='form-select' onChange={(e) => updateForm({ bloodgroup: e.target.value })} >

                                                    {allbloodgroups.map((group) => (
                                                        <option key={group} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </select>
                                            </MDBCol>}

                                        <MDBCol sm={12} md={2}>
                                            <div className="tel-numbers  mt-2">
                                                <strong>Gender</strong>
                                            </div>
                                            <select className='form-select' onChange={(e) => updateForm({ gender: e.target.value })}>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                {/* <option value="others">Others</option> */}
                                            </select>
                                        </MDBCol>

                                        {(data == null || data.phone != 3) &&
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers mt-2 w-100">
                                                    <strong>Phone Number{data && data.phone === 1 && <span className="text-danger">*</span>}</strong>
                                                </div>
                                                <div className="form pt-1 w-100">

                                                    <div className="d-flex align-items-center">
                                                        <PhoneInput
                                                            className="form-control d-flex"
                                                            defaultCountry="IN"
                                                            placeholder="Enter phone number"
                                                            value={form.phone}
                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                            onChange={(value) => updateForm({ phone: value })}

                                                        />
                                                    </div>

                                                    {error.phone && (<><p className="clr-red waiver-error-txt">{error.phone}</p></>)}
                                                </div>
                                            </MDBCol>}
                                        {AdultName && <>
                                            <div className="routing-border mt-3 mb-0">

                                            </div>
                                            <div className="tel-numbers mt-2">
                                                <strong>Consenting Adult</strong>
                                            </div>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers w-100 mt-3">
                                                    <strong>Consenting Adult Name<span className="text-danger">*</span></strong>
                                                </div>
                                                <div className="pt-1 w-100 d-flex ">
                                                    <div className="w-50">
                                                        <input placeholder='First Name' type="text" className="w-100 form-control"
                                                            onChange={(e) => updateForm({ consultingadultfirstname: e.target.value })} />
                                                        {error.consultingadultfirstname && (<><p className="clr-red waiver-error-txt">{error.consultingadultfirstname}</p></>)}
                                                    </div>
                                                    <div className="w-50">
                                                        <input placeholder='Last Name' type="text" className="w-100 ms-2 form-control"
                                                            onChange={(e) => updateForm({ consultingadultlastname: e.target.value })} />
                                                        {error.consultingadultlastname && (<><p className="clr-red waiver-error-txt">{error.consultingadultlastname}</p></>)}
                                                    </div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers mt-3">
                                                    <strong>Consenting Adult Contact<span className="text-danger">*</span></strong>
                                                </div>
                                                <div className="form pt-1 w-100">

                                                    <div className="d-flex align-items-center">
                                                        <PhoneInput
                                                            defaultCountry="IN"
                                                            className="form-control d-flex"
                                                            placeholder="Enter phone number"
                                                            value={form.consultingadultcontact}
                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                            onChange={(value) => updateForm({ consultingadultcontact: value })}

                                                        />
                                                    </div>
                                                    {error.consultingadultcontact && (<><p className="clr-red waiver-error-txt">{error.consultingadultcontact}</p></>)}
                                                </div>
                                            </MDBCol>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers mt-3">
                                                    <strong>Consenting Adult Relationship<span className="text-danger">*</span></strong>
                                                </div>
                                                <div className="form pt-1 w-100">
                                                    <input placeholder='Consenting Adult Relationship' type="text" className="w-100 form-control" onChange={(e) => updateForm({ consultingadultrelation: e.target.value })} />
                                                    {error.consultingadultrelation && (<><p className="clr-red waiver-error-txt">{error.consultingadultrelation}</p></>)}
                                                </div>

                                            </MDBCol>
                                            <div className="routing-border mt-3 mb-3">

                                            </div>
                                        </>
                                        }
                                        {(data == null || data.email != 3) &&
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers mt-3 w-100">
                                                    <strong >Email{data && data.email === 1 && <span className="text-danger">*</span>}</strong>
                                                </div>
                                                <div className="form pt-1 w-100">
                                                    <input placeholder='example@gmail.com' type="text" className="w-100 form-control"
                                                        onChange={(e) => updateForm({ email: e.target.value })} value={form.email} />
                                                    {error.email && (<><p className="clr-red waiver-error-txt">{error.email}</p></>)}
                                                </div>
                                            </MDBCol>}

                                        {(data == null || data.address != 3) &&
                                            <>
                                                <MDBCol sm={12} md={8}>
                                                    <div className="tel-numbers  mt-3">
                                                        <strong>Street/Apartment{data && data.address === 1 && <span className="text-danger">*</span>}</strong>
                                                    </div>
                                                    <input placeholder='Address' type="text" className="w-100 form-control"
                                                        onChange={(e) => updateForm({ street: e.target.value })} />
                                                    {error.street && (<><p className="clr-red waiver-error-txt">{error.street}</p></>)}
                                                </MDBCol>

                                                <MDBCol sm={12} md={4}>
                                                    <div className="tel-numbers  mt-2">
                                                        <strong>Area{data && data.address === 1 && <span className="text-danger">*</span>}</strong>
                                                    </div>
                                                    <input placeholder='Area' type="text" className="w-100 form-control"
                                                        onChange={(e) => updateForm({ area: e.target.value })}
                                                    />
                                                    {error.area && (<><p className="clr-red waiver-error-txt">{error.area}</p></>)}

                                                </MDBCol>
                                                <MDBCol sm={12} md={4}>
                                                    <div className="tel-numbers  mt-2">
                                                        <strong>City{data && data.address === 1 && <span className="text-danger">*</span>}</strong>
                                                    </div>
                                                    <input placeholder='City' type="text" className="w-100 form-control"
                                                        onChange={(e) => updateForm({ city: e.target.value })} />
                                                    {error.city && (<><p className="clr-red waiver-error-txt">{error.city}</p></>)}

                                                </MDBCol>
                                                <MDBCol sm={12} md={4}>
                                                    <div className="tel-numbers  mt-2">
                                                        <strong>Country{data && data.address === 1 && <span className="text-danger">*</span>}</strong>
                                                    </div>
                                                    <CountryDropdown
                                                        className="form-select h-auto"
                                                        value={form.country}
                                                        onChange={(val) => updateForm({ country: val })}
                                                    />
                                                    {error.country && (<><p className="clr-red waiver-error-txt">{error.country}</p></>)}
                                                </MDBCol>
                                            </>}
                                        {(data == null || data.emergencycontact != 3) && <>
                                            <div className="routing-border mt-3 mb-0">

                                            </div>
                                            <div className="tel-numbers mt-2">
                                                <strong>Emergency contact</strong>
                                            </div>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers  mt-2">
                                                    <strong>Name{data && data.emergencycontact === 1 && <span className="text-danger">*</span>}</strong>
                                                </div>
                                                <div className="d-flex ">
                                                    <div className="w-50">
                                                        <input placeholder='First Name' type="text" className="w-100 form-control"
                                                            onChange={(e) => updateForm({ emergencyfirstname: e.target.value })} />
                                                        {error.emergencyfirstname && (<><p className="clr-red waiver-error-txt">{error.emergencyfirstname}</p></>)}
                                                    </div>
                                                    <div className="w-50">
                                                        <input placeholder='Last Name' type="text" className="w-100 ms-2 form-control"
                                                            onChange={(e) => updateForm({ emergencylastname: e.target.value })} />
                                                        {error.emergencylastname && (<><p className="clr-red waiver-error-txt">{error.emergencylastname}</p></>)}
                                                    </div>
                                                </div>

                                            </MDBCol>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers mt-2">
                                                    <strong>Contact Number{data && data.emergencycontact === 1 && <span className="text-danger">*</span>}</strong>
                                                </div>
                                                <div className="form pt-1 w-100">

                                                    <div className="d-flex align-items-center">
                                                        <PhoneInput
                                                            className="form-control d-flex"
                                                            defaultCountry="IN"
                                                            placeholder="Enter phone number"
                                                            value={form.emergencycontact}
                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                            onChange={(value) => updateForm({ emergencycontact: value })}

                                                        />
                                                    </div>
                                                    {error.emergencycontact && (<><p className="clr-red waiver-error-txt">{error.emergencycontact}</p></>)}
                                                </div>

                                            </MDBCol>
                                            <MDBCol sm={12} md={4}>
                                                <div className="tel-numbers  mt-2">
                                                    <strong>Relationship{data && data.emergencycontact === 1 && <span className="text-danger">*</span>}</strong>
                                                </div>
                                                <input placeholder='Relationship' type="text" className="w-100 form-control"
                                                    onChange={(e) => updateForm({ emergencyrelation: e.target.value })} />
                                                {error.emergencyrelation && (<><p className="clr-red waiver-error-txt">{error.emergencyrelation}</p></>)}
                                            </MDBCol>
                                            <div className="routing-border mt-3 mb-3">

                                            </div>
                                        </>}
                                        <div className="tel-numbers mt-2">
                                            <strong>Agreement</strong>
                                        </div>
                                        <div className='m-0' dangerouslySetInnerHTML={{
                                            __html: (data && data.agreement) ? data.agreement :
                                                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
deserunt mollit anim id est laborum`
                                        }} />

                                        <div className='d-flex mt-2'>
                                            <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' className='mt-1'
                                                checked={form.checkBox}
                                                onChange={(e) => updateForm({ checkBox: !form.checkBox })}
                                            />
                                            <div
                                                className='m-0 ms-2'
                                                dangerouslySetInnerHTML={{
                                                    __html: (data && data.checkboxcontent) ? (data.checkboxcontent.replace(/\n/g, '<br />')) :
                                                        ` By checking this box, I acknowledge that I have read, understood, and agree to the terms and conditions
                                        outlined in the waiver form. I accept full responsibility for my participation, or for the participation of the
                                        minor I am legally responsible for, in climbing activities at Crag Studio, and recognize the inherent risks
                                        involved.`
                                                }}
                                            ></div>
                                        </div>
                                        {/* {error.checkBox && (<><p className="clr-red waiver-error-txt">{error.checkBox}</p></>)} */}
                                    </MDBRow>

                                    <div className="mt-2">

                                        <MDBCol sm={12} md={6}>
                                            <div className="tel-numbers">
                                                <strong>Today's Date: </strong>{`${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`}
                                            </div>
                                        </MDBCol>


                                        {merror.length > 0 && <div className="border p-3 mt-3 rounded-2 border-danger bg-danger bg-opacity-10">
                                            <p className="m-0">
                                                <MDBIcon color="danger" fas icon="exclamation-triangle" className="me-2" />Incomplete/wrong data in the below sections:
                                                <ul>
                                                    {merror && merror.map((item) => (
                                                        <li>{item}</li>
                                                    ))}
                                                </ul>
                                            </p></div>}

                                    </div>
                                    <div className="d-flex align-items-center justify-content-center w-100 mt-4">
                                        <MDBBtn onClick={(e) => { setSubmitDisable(true); submitHandler(e); }} disabled={submitDisable} className="d-flex align-items-center">
                                            {loading ? <>
                                                <MDBSpinner size="sm" role="status" tag="span" />
                                            </> :
                                                "Submit"
                                            }
                                        </MDBBtn>
                                    </div>
                                </>}
                            {loading &&
                                <div className="page-loader w-100 h-100">
                                    <div className="p-3 bg-light rounded-3 d-flex align-items-center justify-content-center flex-column" >
                                        <MDBSpinner className='loading-icon' color="dark" role='status' />
                                        <p className="m-0 pt-2" style={{ fontWeight: 500 }}>Submitting... Please wait.</p>
                                    </div>
                                </div>
                            }
                        </MDBCardBody>
                    </MDBCard>

                    <div className="text-center d-block text-secondary mt-2">Powered by Gym Admin Online</div>
                </div>
                <MDBAlert
                    color={(Error2.includes('internet') || Error2.includes('wrong')) ? 'danger' : (Error2.includes('submitted') ? 'success' : 'secondary')}
                    autohide
                    position='top-right'
                    delay={5000}
                    appendToBody
                    open={Error2 != ""}
                    onClose={() => setError2("")}
                >
                    {Error2}
                </MDBAlert>
            </>
        </React.Fragment >
    )
}
export default DigitalWaiver
