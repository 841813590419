import { format, parseISO } from "date-fns";
import {
    MDBIcon,
} from "mdb-react-ui-kit";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReactGA from 'react-ga';
import { useLocation, } from "react-router";
import Loader from "../components/Loader";
import { getCheckMembers1, getSlots, getGymAddonsList, getPlans } from "../services/apiServices.js";
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel";
import PageError from "./PageError";



function Checkinreport() {

    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [fTimeS, setfTimeS] = useState("");
    const [TTimeS, setTTimeS] = useState("");
    const [userData, setData] = useState([]);
    const [inputText, setInputText] = useState("");
    const [loading, setLoading] = useState(true)
    const [slots, setSlots] = useState([]);
    const location = useLocation();
    const [sel, setSel] = useState('')
    const [applyFilterClicked, setApplyFilterClicked] = useState(false);
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [customtime, setCustomtime] = useState(false);
    const [plans, setPlans] = useState([]);
    const [addons, setAddons] = useState([]);
    const currentTime = new Date()
    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, new Date()]);
    const [startDate, endDate] = dateRange;
    const [totalCount, setTotalCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const handleReload = () => {
        window.location.reload();
    }

    useEffect(() => {
        async function fetchpData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const resp = await getPlans(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                if (!resp) {
                    return;
                }
                if (resp === "false") {
                    setApiError(true)
                    return;
                }
                const plans = await resp.json();
                setPlans(plans);
                const respo = await getGymAddonsList(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                if (!respo) {
                    return;
                }


                if (respo === "false") {
                    setApiError(true)
                    return;
                }
                setAddons(await respo.json());
            }
        }
        fetchpData()
        ReactGA.pageview(window.location.pathname);
    }, []);
    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);


    let filter = {}
    const parseTimeStringKolkata = (timeString, date) => {
        const [hours, minutes] = timeString.split(":").map(Number);
        const newDate = new Date(date);
        newDate.setUTCHours(hours - 5, minutes - 30, 0, 0); // Adjust for IST (UTC+5:30)
        return newDate;
    };

    async function fetchData() {

        if (localStorage.getItem("loggedUserInfo")) {

            let search = inputText;

            filter.name = search

            location.limitmember = itemsPerPage;

            location.currentPage = currentPage > 0 && !(currentPage > totalPages) ? currentPage : 1;
            const currentTime = new Date();
            const startOfToday = new Date(currentTime);
            startOfToday.setHours(0, 0, 0, 0);

            // Set end of today
            const endOfToday = new Date(currentTime);
            endOfToday.setHours(23, 59, 59, 999);



            const yesterday = new Date(currentTime);
            yesterday.setDate(currentTime.getDate() - 1);
            const startOfYesterday = new Date(yesterday);
            startOfYesterday.setHours(0, 0, 0, 0);

            const endOfYesterday = new Date(yesterday);
            endOfYesterday.setHours(23, 59, 59, 999);

            const startOfWeek = new Date(currentTime);
            startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
            startOfWeek.setHours(12, 0, 0, 0);
            const endOfWeek = new Date(currentTime);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            endOfWeek.setHours(12, 0, 0, 0);

            const startOfMonth = new Date(currentTime);
            startOfMonth.setDate(1);
            startOfMonth.setHours(0, 0, 0, 0);

            const endOfMonth = new Date(currentTime);

            const startOfYear = new Date(currentTime);
            startOfYear.setMonth(0, 1);
            startOfYear.setHours(0, 0, 0, 0);

            const endOfYear = new Date(currentTime);
            endOfYear.setMonth(11, 31);
            endOfYear.setHours(23, 59, 59, 999);


            const startOfLastYear = new Date(currentTime.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
            const endOfLastYear = new Date(currentTime.getFullYear(), 11, 31, 23, 59, 59, 999);



            const enddate = new Date(endDate)
            enddate.setHours(23, 59, 59, 999)

            const startOfDate = DayFilter === "6" ? startDate : (DayFilter === "" ? startOfLastYear : (DayFilter === '1' ? startOfToday : (DayFilter === '2' ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : "")))))))
            const endOfDate = DayFilter === "6" ? enddate : (DayFilter === "" ? endOfLastYear : (DayFilter === '1' ? endOfToday : (DayFilter === '2' ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : "")))))))

            if ((startOfDate && endOfDate)) {
                const startDatef = new Date(startOfDate)
                startDatef.setHours(0, 0, 0, 0)
                const utcstartDate = startDatef.toISOString();
                // location.filter.startDate = utcstartDate;
                let lastoftheday = new Date(endOfDate);
                lastoftheday.setHours(23, 59, 59, 999);
                const utcEndDate = lastoftheday.toISOString();

                //             location.filter.endDate = utcEndDate;
                filter.startDate = utcstartDate;
                filter.endDate = utcEndDate;

            }
            console.log("from time ", fTimeS, typeof fTimeS);
            console.log("To time ", TTimeS, typeof TTimeS);
            location.filter = filter


            const response = await getCheckMembers1(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
                { ...location, date: new Date() }
            );

            if (!response) {
                return;
            }
            if (response === "false") {
                setApiError(true)
                return;
            }
            const tuserData = await response.json()

            if (!tuserData) {
                return;
            }
            let filteredData = tuserData.attendance
            if (fTimeS !== "" && TTimeS !== "") {
                filteredData = tuserData.attendance.filter((item) => {
                    const intime = new Date(item.intime);

                    // Convert intime to Asia/Kolkata time zone
                    const localIntime = new Date(intime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));

                    // Parse the fTimeS and tTimeS to be in the correct time zone
                    const fTime = parseTimeStringKolkata(fTimeS, localIntime);
                    const tTime = parseTimeStringKolkata(TTimeS, localIntime);

                    // Check if the localIntime is within the specified time range
                    return localIntime >= fTime && localIntime <= tTime;
                });
            }
            // Set the filtered data to state
            setData(filteredData);
            console.log(filteredData);
            // setData(tuserData.attendance);
            console.log(tuserData.attendance);
            setTotalCount(tuserData.total)

            setLoading(false)
            setApplyFilterClicked(false)
            setResetFilterClicked(false)
        }
    }


    const getDates = (obj) => {
        setDateRange(obj);
    };

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="form-control select-input placeholder-active me-3 shadow-1 form-selectMobile">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));



    // console.log(userData);
    const tmrowr = []

    userData && userData.length > 0 && userData.map((itm, index) => {
        if (itm.member.length > 0) {
            let Plandetails = [];
            let addondetails = [];
            if (plans.some((item) => item._id === itm.planid)) {
                Plandetails = plans.filter((item) => item._id === itm.planid)
            }
            else {
                addondetails = addons.filter((item) => item._id === itm.planid)
            }
            const rarray = [];
            rarray.push(index + 1 + ((currentPage - 1) * itemsPerPage))
            rarray.push(itm.member.length > 0 && (itm.member[0].fname + " " + itm.member[0].lname));
            rarray.push(itm.trainer ? itm.trainer : "--");
            rarray.push(Plandetails.length > 0 ? Plandetails[0].name : "--");
            rarray.push(addondetails.length > 0 ? addondetails[0].name : "--");
            rarray.push(itm.membershipstatus ? (itm.membershipstatus === "1" ? "Active" : "Inactive") : <div className="title">{!itm.member[0].suspend ? itm.member[0].mStatus ? itm.member[0].mStatus : (new Date(itm.member[0].expirydate) > new Date() ? "Active" : "Inactive") : "suspend"}</div>)
            // rarray.push(<div className="title">{!itm.member[0].suspend ? itm.member[0].mStatus ? itm.member[0].mStatus : (new Date(itm.member[0].expirydate) > new Date() ? "Active" : "Inactive") : "suspend"}</div>);
            rarray.push(itm.intime ? format(parseISO(itm.intime), "dd-MM-yyyy") : format(parseISO(itm.outtime), "dd-MM-yyyy"));
            rarray.push(

                new Date(itm.intime)
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })


            );

            /**
             * @description
             * the below condition is for if checkout time not completed then it shows Invalid date so to overcome we write this condition 
             * 
             * by vamshi
             * */

            rarray.push(
                (new Date(itm.outtime)
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })) === "Invalid Date" ? "NA" : new Date(itm.outtime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })
            );
            const intime = new Date(itm.intime);
            const outtime = new Date(itm.outtime);

            if (!isNaN(intime) && !isNaN(outtime)) {
                const timeDiff = Math.abs(outtime - intime);
                const hours = Math.floor(timeDiff / (60 * 60 * 1000));
                const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));


                rarray.push(`${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}`);

            } else {
                rarray.push("--");
            }

            // return rarray;
            tmrowr.push(rarray)
        }
        // return null;
    })


    const basicData = {
        columns: ["S.no", 'Name', "Trainer", "Plan", "Addon", "Status", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
        rows: tmrowr,
    };

    useEffect(() => {
        async function fetchSData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getSlots(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                if (!response) {
                    return;
                }
                if (response === "false") {
                    setApiError(true)
                    return;
                }
                const slots = await response.json();
                console.log(slots, '999999999999');

                setSlots(slots)
            }
        }
        fetchSData()

    }, [])




    useEffect(() => {
        const fetchTotalPages = async () => {
            const totalPagesCount = Math.ceil(totalCount / itemsPerPage);
            setTotalPages(userData && userData.length > 0 ? totalPagesCount : 1);
        };

        setLoading(true)



        fetchTotalPages();
        // fetchData();
    }, [currentPage, totalCount, itemsPerPage]);




    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
        setLoading(true)
    };

    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
        setLoading(true)
    };


    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked, currentPage, totalCount, itemsPerPage]);

    const applyHandler = () => {
        setCurrentPage(1)
        setApplyFilterClicked(true)
        // fetchData()
    }

    const handleclear = () => {
        
        setInputText("")
        setDayFilter("4")
        setfTimeS("")
        setTTimeS("")
        setCurrentPage(1);

        setCustomtime(false)
        const currentTime = new Date()
        const startOfMonth = new Date(currentTime);
        startOfMonth.setDate(1);
        startOfMonth.setHours(0, 0, 0, 0);
        setSel("")
        setDateRange([startOfMonth, new Date()])
        setResetFilterClicked(true)
        // fetchData()
    }

    const downloaddata = async (e) => {
        fetchDataAndExport();
    }




    const fetchDataAndExport = async () => {
        location.limitmember = totalCount;

        location.currentPage = 1;

        const response = await getCheckMembers1(
            localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
            { ...location, date: new Date() }
        );

        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }
        const tuserData = await response.json()


        const tmrowr = tuserData.attendance && tuserData.attendance.length > 0 && tuserData.attendance.map((itm, index) => {
            if (itm.member.length > 0 && itm.intime) {
                let Plandetails = [];
                if (plans.some((item) => item._id === itm.planid)) {
                    Plandetails = plans.filter((item) => item._id === itm.planid)
                }
                else {
                    Plandetails = addons.filter((item) => item._id === itm.planid)
                }
                const rarray = [];
                rarray.push(index + 1 + ((currentPage - 1) * itemsPerPage))
                rarray.push(itm.member.length > 0 && (itm.member[0].fname + " " + itm.member[0].lname));
                rarray.push(itm.trainer);
                rarray.push(Plandetails.length > 0 ? Plandetails[0].name : "");
                rarray.push(itm.membershipstatus ? (itm.membershipstatus === "1" ? "Active" : "Inactive") : <div className="title">{!itm.member[0].suspend ? itm.member[0].mStatus ? itm.member[0].mStatus : (new Date(itm.member[0].expirydate) > new Date() ? "Active" : "Inactive") : "suspend"}</div>)
                rarray.push(parseISO(itm.intime));
                rarray.push(
                    new Date(itm.intime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })
                );

                /**
                 * @description
                 * the below condition is for if checkout time not completed then it shows Invalid date so to overcome we write this condition 
                 * 
                 * by vamshi
                 * */

                rarray.push(
                    (new Date(itm.outtime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })) === "Invalid Date" ? "NA" : new Date(itm.outtime)
                            .toLocaleTimeString("en-IN", {
                                timeZone: "Asia/Kolkata",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            })
                );
                const intime = new Date(itm.intime);
                const outtime = new Date(itm.outtime);

                if (!isNaN(intime) && !isNaN(outtime)) {
                    const timeDiff = Math.abs(outtime - intime);
                    const hours = Math.floor(timeDiff / (60 * 60 * 1000));
                    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));


                    rarray.push(`${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}`);

                } else {
                    rarray.push("--");
                }

                return rarray;
            }
            return null;
        }).filter(Boolean);

        // console.log(tmrowr);
        const updatedBasicData = {
            columns: ["S.no", 'Name', "Trainer", "Plan/Addon", "Status", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
            rows: tmrowr,
        };
        ExcelExportButton(updatedBasicData, "checkInReportsData");
    };
    return (

        <>
            <>
                {/* <LeftPane isactive="13" /> */}
                {/* <Header /> */}
                {/* <Reports /> */}
            </>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="">

                <div className="gym-container gym-cnr" id="showcase">

                </div>

                <div className="d-flex flex-wrap" >

                    <div className="gym-section w-100-mobile">
                        <label className="fw-bold">Search: </label>
                        <div>
                            <input
                                type="text"
                                className="form-control select-input placeholder-active me-3 shadow-1  "
                                placeholder="Search by Name"
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                            // onChange={inputHandler}
                            />
                        </div>
                    </div>

                    <div className="gym-section w-100-mobile ms-3">
                        <label className="fw-bold"> Days: </label>
                        <div>
                            <select
                                name="shrs"
                                id="shr"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 "
                                onChange={(e) => setDayFilter(e.target.value)}
                                value={DayFilter}
                            >
                                <option value="">All</option>
                                <option value="1">Today</option>
                                <option value="2">Yesterday</option>
                                <option value="3">This Week</option>
                                <option value="4">This Month</option>
                                <option value="5">This Year</option>
                                <option value="6">Custom</option>
                            </select>
                        </div>
                    </div>

                    {customdate && <div className="gym-section w-100-mobile ms-3">
                        <label className="fw-bold"> Select Custom Date: </label>
                        <DatePicker
                            dateFormat={"d/M/yyyy"}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                getDates(update);
                            }}
                            isClearable={false}
                            customInput={<CalenderInput />}
                        />
                    </div>}
                    <div className="gym-section w-100-mobile ms-3">
                        <label className="fw-bold"> Time Slot: </label>
                        <div>

                            <select
                                name="shrs"
                                id="ehr"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile "
                                onChange={(e) => {
                                    const selectedSlotId = e.target.value;
                                    const selectedSlot = slots.find((slot) => slot._id === selectedSlotId);
                                    // console.log(selectedSlot);
                                    if (selectedSlotId === '7') {
                                        setCustomtime(true)
                                        setSel(selectedSlotId);
                                    }
                                    if (selectedSlotId === '') {
                                        setCustomtime(false)
                                        setSel(selectedSlotId);
                                    }

                                    if (selectedSlot && selectedSlotId !== '7') {
                                        setSel(selectedSlotId);
                                        setCustomtime(false)
                                        setfTimeS(
                                            selectedSlot.shflag === "1"
                                                ? `${selectedSlot.shrs}:${selectedSlot.smins === "1" ? "00" : selectedSlot.smins === "2" ? "15" : selectedSlot.smins === "3" ? "30" : "45"}`
                                                : ((selectedSlot.shrs) === "12" ? `${Number(selectedSlot.shrs)}:${selectedSlot.smins === "1" ? "00" : selectedSlot.smins === "2" ? "15" : selectedSlot.smins === "3" ? "30" : "45"} ` : `${Number(selectedSlot.shrs) + 12}:${selectedSlot.smins === "1" ? "00" : selectedSlot.smins === "2" ? "15" : selectedSlot.smins === "3" ? "30" : "45"} `)
                                        );
                                        setTTimeS(
                                            selectedSlot.ehflag === "1"
                                                ? `${Number(selectedSlot.ehrs)}:${selectedSlot.emins === '1' ? "00" : selectedSlot.emins === '2' ? "15" : selectedSlot.emins === '3' ? "30" : "45"}`
                                                : ((selectedSlot.ehrs) === "12" ? `${Number(selectedSlot.ehrs)}:${selectedSlot.emins === '1' ? "00" : selectedSlot.emins === '2' ? "15" : selectedSlot.emins === "3" ? "30" : "45"}` : `${Number(selectedSlot.ehrs) + 12}:${selectedSlot.emins === "1" ? "00" : selectedSlot.emins === "2" ? "15" : selectedSlot.emins === "3" ? "30" : "45"}`)
                                            // - (selectedSlot.emins == 1 ? 1 : 0)
                                        );
                                    }
                                }}
                                value={sel}
                            >
                                <option value={''}>Select Time Slot</option>
                                {slots.map((value, index) => (
                                    <option key={index} value={value._id}>{value.name} </option>
                                ))}
                                <option value={'7'}> Custom Time</option>
                            </select>
                        </div>
                    </div>

                    {customtime && <div className="d-flex w-100-mobile">
                        <div className="gym-section w-100-mobile  ms-3">
                            <label className="fw-bold">From</label>
                            <select
                                name="shrs"
                                id="shr"
                                className="gym-input forminput d-inline-block me-3 form-selectMobile"
                                onChange={(e) => { setfTimeS((e.target.value) + ":00") }
                                }
                                value={parseInt(fTimeS.split(":")[0])}
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                ))}
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                ))}
                            </select>
                        </div>
                        <div className="gym-section w-100-mobile ms-3">
                            <label className="fw-bold">To</label>
                            <select
                                name="shrs"
                                id="ehr"
                                className="gym-input forminput d-inline-block me-3 form-selectMobile"
                                onChange={(e) => setTTimeS((e.target.value) + ":00")}
                                value={parseInt(TTimeS.split(":")[0])}
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                ))}
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                ))}
                            </select>
                        </div>
                    </div>}

                    <div className="gym-section w-100-mobile ms-3">
                        <div className="mt-3 pt-1 filterApplyMobile">
                            <button
                                type="submit"
                                className="me-3 btn btn-primary"
                                onClick={() => applyHandler()}
                            >
                                Apply
                            </button>
                            <button
                                type="submit"
                                className="mt-2 btn btn-secondary"
                                onClick={() => { handleclear() }}
                            >
                                Clear
                            </button>
                            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                onClick={(e) => downloaddata()}
                            >
                                <MDBIcon fas icon="download" className="d-inline me-1" />Export
                            </button>}
                        </div>
                    </div>
                </div>
                {loading ? (<h4><Loader /></h4>) :
                    // <MDBDatatable fixedHeader className="mt-4" data={searchInput ? basicData1 : basicData} entries={25} />

                    <div className="datatable reports">
                        <div className="reportsDataTable">
                            <table className="table datatable-table">
                                <thead className="datatable-header">
                                    <tr>
                                        {basicData.columns.map((column, index) => (
                                            <th key={index}>{column}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!basicData.rows && loading ? (<h4> <Loader /></h4>) : basicData.rows.length > 0 && basicData.rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}  >
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {!loading && userData.length === 0 &&
                                        <tr>
                                            <td colSpan={9}><p className="d-flex align-items-center justify-content-center"> No Data Found</p></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="datatable-pagination">
                            Rows per page
                            <div className="ms-2 form-control w-auto">
                                <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="Pagenation-Select">
                                    <option value="50">
                                        <div className="select-option">
                                            50
                                        </div>
                                    </option>
                                    <option value="100">
                                        <div className="select-option">
                                            100
                                        </div>
                                    </option>
                                    <option value="500">
                                        <div className="select-option">
                                            500
                                        </div>
                                    </option>
                                    <option value="1000">
                                        <div className="select-option">
                                            1000
                                        </div>
                                    </option>
                                </select>
                            </div>
                            <button onClick={prevPage}
                                disabled={currentPage === 1}
                                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                                Previous
                            </button>
                            <div className="d-flex justify-content-between w-120px align-items-center"> Page
                                {/* {currentPage}  */}
                                <input className="form-control w-50px" value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                                of {totalPages}</div>
                            <button onClick={nextPage}
                                disabled={currentPage === totalPages}
                                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                                Next
                            </button>
                        </div>
                    </div>


                }
            </div >}
        </>
    )
}


export default Checkinreport;