import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { getStaffProfile, saveStaff, logEvent } from "../services/apiServices.js";
import {
  isEmail,
  isEmpty,
} from "../validators/helper.js";
import {  MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import 'react-phone-number-input/style.css'
import PhoneInput, {  isValidPhoneNumber } from 'react-phone-number-input'
import PageError from "../views/PageError.js";
import { googleAnalytic } from "./googleAnalytics.js";

export default function CreateStaff(props) {

  const [error, setError] = useState({});
  const [userForm, setuserForm] = useState({
    ID: "",
    firstname: "",
    lastname: "",
    username: "",
    password1: "",
    number: "",
    gender: "",
    age: "",
    email: "",
    role: "admin",
  });
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(false);
  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {

    async function fetchData() {
      console.log(props.sId);
      const response = await getStaffProfile(props.sId);
      console.log(response);

      if (!response) {
        return;
      }
      if (response == "false") {
        setApiError(true)
        return;
      }
      const record = await response.json();
      if (!record) {
        navigate("/");
        return;
      }
      setuserForm(record);
      if (!record.username) {
        updateForm({ username: record.email })
      }
    }
    if (props.sId) {
      fetchData();
    }
  }, [navigate]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  function updateForm(value) {
    return setuserForm((prev) => {
      return { ...prev, ...value };
    });
  }
  const isAlphabetical = (str) => /^[a-zA-Z]+$/.test(str);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};

    if (!isEmail(userForm.email)) {
      errors.email = "Please enter valid email";
    }
   
    if (isEmpty(userForm.username)) {
      errors.username = "Username can't be blank";
    }
    if (isEmpty(userForm.firstname)) {
      errors.firstname = "First Name can't be blank";
    } else if (!isEmpty(userForm.firstname) && !isAlphabetical(userForm.firstname)) {
      errors.firstname = "Name can be only Alhabets  ";
    }
    if (isEmpty(userForm.lastname)) {
      errors.lastname = "Last Name can't be blank";
    } else if (!isEmpty(userForm.lastname) && !isAlphabetical(userForm.lastname)) {
      errors.lastname = "Name can be only Alhabets  ";
    }
    if (isEmpty(userForm.number)) {
      errors.number = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(userForm.number)) {
      errors.number = "Please enter valid Contact Number";
    }
  

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  async function onSubmit(e) {
 
    e.preventDefault();
    const updates = {
      ID: userForm.ID,
      fname: userForm.firstname,
      lname: userForm.lastname,
      username: userForm.username,
      password: userForm.password1,
      number: userForm.number,
      gender: userForm.gender,
      age: userForm.age,
      email: userForm.email,
      role: userForm.role,
      _id: props.sId ? props.sId : null,
    };
    console.log(userForm);
    console.log("inn if");
    let errors = validateForm(e);
    if (errors == true) {
      if (localStorage.getItem("loggedUserInfo")) {
        let saverep = await saveStaff(
          updates,
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (saverep == "false") {
          setApiError(true)
          return;
        }
        let staff = await saverep.json();
        console.log(staff);
        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let Eventtype = "staff";
        if (props.sId == " " ? true : false) {
          let flag = `Staff Created Successfull (${updates.fname} ${updates.lname})`;
          let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
          let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
          await googleAnalytic("createStaff", 'Staff', 'Add Button', 'Create Staff')
          // ReactGA.event({
          //   category: 'Staff',
          //   action: 'Add Button',
          //   label: 'Create Staff',
          // });
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
        }
        else {
          let flag = `Staff Updated Successfull (${updates.fname} ${updates.lname})`;
          let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
          let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
          await googleAnalytic("createStaff", 'Staff', 'Save Button', 'Staff Updated')
          // ReactGA.event({
          //   category: 'Staff',
          //   action: 'Save Button',
          //   label: 'Staff Updated',
          // });
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
        }
      }
      window.location.reload(true);
    } else {
      setError(errors);
    }
  }
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <div className="gym-container ">
        <form
          action="#"
          className="business-form"
          target="_blank"
          onSubmit={onSubmit}
        >

          <MDBRow>

            <MDBCol md={4} sm={12} className="pt-4">
              <label>Employee ID</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.ID}
                onChange={(e) => updateForm({ ID: e.target.value })}
              />
              {error.ID && (
                <>
                  <p className="clr-red">{error.ID}</p>
                </>
              )}
            </MDBCol>


            <MDBCol md={4} sm={12} className="pt-4">
              <label>Mobile No<span className="text-danger">*</span></label>
          
              <div className="d-flex align-items-center">
                <PhoneInput
                  className="gym-input user-pdng input-select d-flex p-0"
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  value={userForm.number}
                  // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                  onChange={(value) => updateForm({ number: value })}
                />
              </div>
              {error.number && (
                <>
                  <p className="clr-red">{error.number}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>First Name<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.firstname}
                onChange={(e) => updateForm({ firstname: e.target.value })}
              />
              {error.firstname && (
                <>
                  <p className="clr-red">{error.firstname}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>Last Name<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.lastname}
                onChange={(e) => updateForm({ lastname: e.target.value })}
              />
              {error.lastname && (
                <>
                  <p className="clr-red">{error.lastname}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>User Name<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="username"
                required=""
                value={userForm.username}
                onChange={(e) => updateForm({ username: e.target.value })}
              />
              {error.username && (
                <>
                  <p className="clr-red">{error.username}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>Password</label>
              <input
                className="gym-input user-pdng"
                type="password"
                name="Name"
                required=""
                value={userForm.password1}
                onChange={(e) => updateForm({ password1: e.target.value })}
              />
              {error.password && (
                <>
                  <p className="clr-red">{error.password1}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>Gender</label>
              <select
                name="gender"
                id="gender"
                className="gym-input user-pdng"
                value={userForm.gender}
                onChange={(e) => updateForm({ gender: e.target.value })}
              >
                <option value="Male" default>
                  Male
                </option>
                <option value="Female">Female</option>
              </select>
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>Age</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.age}
                onChange={(e) => updateForm({ age: e.target.value })}
              />
              {error.age && (
                <>
                  <p className="clr-red">{error.age}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4">
              <label>Email<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Email"
                required=""
                value={userForm.email}
                onChange={(e) => updateForm({ email: e.target.value })}
              />
              {error.email && (
                <>
                  <p className="clr-red">{error.email}</p>
                </>
              )}
            </MDBCol>




            <MDBCol md={4} sm={12} className="pt-4 pb-5">
              {localStorage.getItem("loggedUserInfo") &&
                !(JSON.parse(localStorage.getItem("loggedUserInfo")).role == "staff") && <div>
                  <label>Role</label>
                  <select
                    name="role"
                    id="role"
                    className="gym-input user-pdng"
                    value={userForm.role}
                    onChange={(e) => updateForm({ role: e.target.value })}
                  >
                    <option value="admin" default>Admin</option>
                    <option value="staff">Staff</option>
                    <option value="accountant">Accountant</option>
                  </select>
                </div>}
            </MDBCol>
          </MDBRow>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-mdb-dismiss="modal"
              onClick={props.backallback}
            >
              {props.sId ? "Cancel" : "Back"}
            </button>
            &nbsp;
            <button
              type="submit"
              className="btn btn-primary"
              data-mdb-dismiss="modal"
            >
              {props.sId ? "Save" : "Add"}
            </button>
          </div>
        </form >
      </div >}
    </>
  );
}
