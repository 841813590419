import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import PasswordStrengthChecker from "../components/PasswordValidator";
import { createGBussinessInfo } from "../services/apiServices.js";
import { isEmail, isEmpty } from "../validators/helper.js";
import logImg from "./../components/images/GymLogo.png";
import logBg from "./../components/images/login-page-compressed.png";
import PageError from "./PageError";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import { googleAnalytic } from "../components/googleAnalytics";

const LoginSignup = ({ setToken }) => {
  const [error, setError] = useState({});
  const [captchaValue, setCaptchaValue] = useState(false);
  const [merror, setSError] = useState();
  const [strongPassword, setstrongPassword] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [signupForm, setsignupForm] = useState({
    firstname: "",
    lastname: "",
    number: "",
    email: "",
    password: "",
    confirmp: "",
    tempcaptcha: "",
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const planname = params.get('name') ? params.get('name') : "Free Trail";
  // console.log("planid :- " + planid + " " + " " + "planname :- " + planname);
  const CLIENT_ID = "68288337026-6p3096b5r2ukcpvbvggtrgsinp72eegd.apps.googleusercontent.com";
  const navigate = useNavigate();
  let validateForm = (e) => {
    e.stopPropagation();
    console.log("Validating form...");
    let errors = {};
    console.log(signupForm.name);

    if (isEmpty(signupForm.email)) {
      errors.email = "Email can't be blank";
    } else if (!isEmail(signupForm.email)) {
      errors.email = "Please enter valid email";
    }
    if (isEmpty(signupForm.password)) {
      errors.passwordstrong = "Password can't be blank";
    }
    else if (!strongPassword.istrong) {
      errors.passwordstrong = "Please enter strong password!";
    }
    else if (signupForm.password !== signupForm.confirmp) {
      errors.confirmp = "Password not matching";
    }
    else if (!captchaValue) {
      errors.tempcaptcha = "Please check the captcha"
    }
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    localStorage.getItem("number") &&
      updateForm({ number: localStorage.getItem("number") }) && localStorage.removeItem("number")
  }, []);
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updates = {
      firstname: signupForm.firstname,
      lastname: signupForm.lastname,
      email: signupForm.email,
      password: signupForm.password,
      through: "signup",
      number: signupForm.number,
      planname: planname,
    };
    let noerrors = validateForm(e);
    console.log(noerrors);
    if (noerrors === true) {
      let saverep = await createGBussinessInfo(updates);
      if (saverep === "false") {
        setApiError(true)
        return;
      }
      let jsOBj = await saverep.json();
      console.log(saverep.status);
      await googleAnalytic("LoginSignup", 'Signup', 'Signup Button', 'Signup')
      if (saverep.status !== 200) {
        console.log("Email already exits in the system!");
      }
      else {
        console.log(jsOBj);
        console.log(jsOBj.token);
        setToken(jsOBj.token);
        setUserSession(jsOBj.userInfo);
        navigate("/dashboard");
      }
    } else {
      setError(noerrors);
    }
  }; function setPasswordStatus(tflag) {
    setstrongPassword(tflag);
  }
  function updateForm(value) {
    return setsignupForm((prev) => {
      return { ...prev, ...value };
    });
  }
  function setUserSession(userInfo) {
    localStorage.setItem("loggedUserInfo", JSON.stringify(userInfo));
  }

  async function serverSumit(updates) {
    let saverep = await createGBussinessInfo(updates);
    console.log(saverep);
    if (saverep === "false") {
      setApiError(true)
      return;
    }
    let jsOBj = await saverep.json();
    console.log(saverep.status);
    if (saverep.status !== 200) {
      setSError("Email already exits in the system!");
    } else {
      console.log(jsOBj);
      console.log(jsOBj.token);
      setToken(jsOBj.token);
      setUserSession(jsOBj.userInfo);
      navigate("/dashboard");
    }
  }
  function responseGmail(resp) {
    console.log("response from gmail..");
    console.log(resp);
    let data = jwt_decode(resp.credential);
    console.log(data);
    const updates = {
      email: data.email,
      password: "d3fault",
      through: "google",
      planname: planname,
      number: signupForm.number,
    };
    serverSumit(updates);
  }

  useEffect(() => {
    /* global google*/
    google.accounts.id.initialize({
      client_id: CLIENT_ID + "",
      callback: responseGmail,
      context: "signup",
    });

    google.accounts.id.renderButton(document.getElementById("signinDiv"),
      { theme: "outline", size: "large" }
    );
  }, []);
  function verifyCaptchaCallback(resp) {
    console.log("Respons for captcha");
    console.log(resp);
    if (!resp) {
      setCaptchaValue(false);
    }
    else {
      console.log("true");
      setCaptchaValue(true);
    }
  }
  const handleReload = () => {
    window.location.reload();
  }
  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/">
          <MDBBtn>
            <MDBIcon fas icon="arrow-left" />
          </MDBBtn>
        </Link>
      </div>
      {/* Hero Start */}
      <section className="cover-user bg-white">
        {apiError ? <PageError handleReload={handleReload} /> : <div className="container-fluid px-0">
          <Row className="g-0 position-relative">
            <Col lg={4} className="cover-my-30 ">
              <div className="cover-user-img d-flex align-items-center justify-content-center">
                <Row>
                  <div className="col-12">
                    <div className="d-flex flex-column auth-hero">
                      <div className="mb-md-0 pb-md-3 text-center">
                        <Link to="#"><img src={logImg} className="gymlogo1" alt="" /></Link>
                      </div>

                      <MDBCard className="border border-success w-100">
                        <MDBCardBody>
                          <MDBCardTitle>SignUp</MDBCardTitle>
                          <div className="title-heading my-lg-auto">
                            <div className="border-0" style={{ zIndex: 1 }}>
                              <CardBody className="p-0 margin__signuo">
                                {/* <h4 className="card-title">Signup</h4> */}
                                <form className="login-form mt-4" onSubmit={submitHandler}>
                                  <div style={{ color: "red" }}>{merror}</div>
                                  <Row>
                                    <Col md={12} >
                                      <div className="mb-3">
                                        <label className="form-label">Your Mobile Number.<span className="text-danger">*</span></label>
                                        <input type="text"
                                          className="form-control"
                                          // placeholder="First Name" name="s"
                                          value={signupForm.number}
                                          disabled />
                                      </div>
                                    </Col>
                                    <Col md={6} >
                                      <div className="mb-3">
                                        <label className="form-label">First Name<span className="text-danger">*</span></label>
                                        <input type="text"
                                          className="form-control" placeholder="First Name" name="s"
                                          onChange={(e) => updateForm({ firstname: e.target.value })} value={signupForm.firstname}
                                          required />
                                      </div>
                                    </Col>
                                    {error.firstname && (
                                      <>
                                        <p className="clr-red">{error.firstname}</p>
                                      </>
                                    )}
                                    <Col md={6} >
                                      <div className="mb-3">
                                        <label className="form-label">Last Name<span className="text-danger">*</span></label>
                                        <input type="text"
                                          className="form-control" placeholder="Last Name" name="s"
                                          onChange={(e) => updateForm({ lastname: e.target.value })} value={signupForm.lastname}
                                          required />
                                      </div>
                                    </Col>
                                    {error.lastname && (
                                      <>
                                        <p className="clr-red">{error.lastname}</p>
                                      </>
                                    )}
                                    <Col md={12} >
                                      <div className="mb-3">
                                        <label className="form-label">Your Email<span className="text-danger">*</span></label>
                                        <input type="email"
                                          className="form-control" placeholder="Email" name="email"
                                          onChange={(e) => updateForm({ email: e.target.value })} value={signupForm.email}
                                          required />
                                      </div>
                                    </Col>
                                    {error.email && (
                                      <>
                                        <p className="clr-red">{error.email}</p>
                                      </>
                                    )}

                                    <Col md={12}>
                                      <PasswordStrengthChecker
                                        setPasswordInput={updateForm}
                                        setPasswordStatus={setPasswordStatus}
                                      />
                                      {error.passwordstrong && (
                                        <>
                                          <p className="clr-red">{error.passwordstrong}</p>
                                        </>
                                      )}
                                    </Col>

                                    <Col md={12}>
                                      <div className="mb-0">
                                        <label className="form-label">Confirm Password<span className="text-danger">*</span></label>
                                        <input type="password"
                                          className="form-control" placeholder="Confirm Password"
                                          onChange={(e) => updateForm({ confirmp: e.target.value })} value={signupForm.confirmp}
                                          required />
                                      </div>
                                    </Col>
                                    {error.confirmp && (
                                      <>
                                        <p className="clr-red">{error.confirmp}</p>
                                      </>
                                    )}

                                    <Col md={12}>
                                      <div className="mb-3">
                                        <ReCAPTCHA
                                          className="mt-3"
                                          sitekey={"6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_"}
                                          onChange={
                                            // (e) =>
                                            verifyCaptchaCallback
                                            // ({ captcha: e.target.value })
                                          }
                                          theme="light"
                                          size="large"
                                        />
                                      </div>
                                    </Col>
                                    {error.tempcaptcha && (
                                      <>
                                        <p className="clr-red">{error.tempcaptcha}</p>
                                      </>
                                    )}
                                    <Col md={14}>
                                      <div className="mb-3">

                                        <div id="signinDiv" className="google-email">

                                        </div>
                                      </div>
                                    </Col>
                                    <div className="col-lg-12 mb-0">
                                      <div className="d-grid">
                                        <button type="submit" className="btn btn-primary">Signup</button>
                                      </div>
                                    </div>
                                    <div className="mx-auto">
                                      <p className="mb-0 mt-3"><span className="text-dark me-2 font-14px">Already have an account ?</span> <Link to="/login" className="fw-bold fs-6 px-0 ps-1 text-primary">Login</Link></p>
                                    </div>
                                  </Row>
                                </form>
                              </CardBody>
                            </div>
                          </div>

                        </MDBCardBody>
                      </MDBCard>
                      <div className="footer mb-md-0 text-center">
                        <p className="mb-0 mt-2 text-muted">© {(new Date().getFullYear())}{" "} Gym Admin Online.</p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>

            <div className="col-lg-8  padding-less img" style={{ backgroundImage: `url(${logBg})` }} data-jarallax='{"speed": 0.5}'>
              <div className="greenoverlay"></div>
            </div>
          </Row>
        </div>}
      </section>
      {/* end section */}
    </React.Fragment>
  );
};

export default LoginSignup;
